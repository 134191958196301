const storagePrefix = "xstak_studio_";

const storage = {
  getToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}token`) as string
    );
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  setRemember: (rememberState: any) => {
    window.localStorage.setItem(
      `${storagePrefix}remember`,
      JSON.stringify(rememberState)
    );
  },
  getRemember: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}remember`) as string
    );
  },
  getApps: () => {
    return JSON.parse(window.localStorage.getItem('apps') as string);
  },
};

export default storage;
