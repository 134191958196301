import React, { useState } from "react"
import { options } from "../../NavBar/utils/constants"
import storage from "../../NavBar/utils/storage"
import HyprIcon from "../../icons/hypr"
import OmniChannelIcon from "../../icons/oe"
import OmniRetainIcon from "../../icons/or"
import ShopdeskIcon from "../../icons/shopdesk"
import XpayIcon from "../../icons/xpay"
import CustomerDataPlatform from "../../icons/customerDataPlatform"

type opt = {
  hasAccess?: boolean
  tabKey: any
  heading: string
}

const Products = ({ tabKey, heading }: opt) => {
  const apps = storage.getApps()
  const length = apps && apps.length > 0

  const paymentOptions = options.filter((solution) => solution?.module == "payment")
  const commerceOptions = options.filter(
    (solution) => solution?.module == "commerce"
  )
  const marketingOptions = options.filter(
    (solution) => solution?.module == "marketing"
  )

  let solutions: any = []
  if (tabKey?.tabKey === "4") {
    solutions.push(...paymentOptions)
  } else if (tabKey?.tabKey === "2") {
    solutions.push(...commerceOptions)
  } else if (tabKey?.tabKey === "3") {
    solutions.push(...marketingOptions)
  } else {
    solutions.push(...options)
  }

  return (
    <div
      className={`dashboard__tilesContainer col-1 ${
        length ? "tilesContainer__two" : "tilesContainer__one"
      } `}
    >
      {solutions.map(
        ({ id, permission, altText, imgSrc, type, link, text, xstakURL }) => {
          let isExist
          if (id === 1) {
            isExist = false
          } else {
            isExist = apps && apps.length > 0 ? apps.includes(permission) : false
          }
          return (
            <SingleCard
              isExist={isExist}
              link={link}
              id={id}
              text={text}
              type={type}
              xstakURL={xstakURL}
              heading={heading}
            />
          )
        }
      )}
    </div>
  )
}

const SingleCard = ({
  isExist,
  link,
  id,
  text,
  type,
  xstakURL,
  heading,
}: any) => {
  const obj: any = {
    heading: heading,
    text: text,
  }
  const [onHover, setOnHover] = useState(false)
  const getIcons = (type: string) => {
    const icon: any = {
      hypr: <HyprIcon fill={onHover ? "#fff" : ""} />,
      oe: <OmniChannelIcon fill={onHover ? "#fff" : ""} />,
      or: <OmniRetainIcon fill={onHover ? "#fff" : ""} />,
      shopdesk: <ShopdeskIcon fill={onHover ? "#fff" : ""} />,
      xpay: <XpayIcon fill={onHover ? "#fff" : ""} />,
      cdp: <CustomerDataPlatform fill={onHover ? "#fff" : ""} />,
    }
    return icon?.[type]
  }

  return (
    <React.Fragment key={id}>
      <a
        href={isExist ? link : xstakURL}
        target={isExist ? "_self" : "_blank"}
        onClick={(e) => {
          if (isExist) {
            if (e.ctrlKey) {
              return
            } else {
              window.location.assign(link)
              window.sessionStorage.setItem("module", JSON.stringify(obj))
                          }
          } else {
            window.sessionStorage.removeItem("module")
          }
        }}
        className={isExist ? `product-dashboard__tiles` : `dashboard__tiles  `}
        onPointerEnter={() => setOnHover(true)}
        onPointerLeave={() => setOnHover(false)}
      >
        {getIcons(type)}
        <h1 className="h6 text-lg">{text} </h1>
      </a>
    </React.Fragment>
  )
}
export default Products
