import React from "react"

const OmniRetainIcon = ({ ...props }) => {
    return (
      <>
        <svg
          width="36"
          height="36"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.3333 8.83207V2.83333C28.3333 1.2711 27.0622 0 25.5 0H2.83333C1.2711 0 0 1.2711 0 2.83333V17.5667C0 19.1289 1.2711 20.4 2.83333 20.4H18.1333V22.1C18.1333 22.2508 18.1928 22.3947 18.2994 22.5006L20.9667 25.1679V33.4333C20.9667 33.7468 21.2198 34 21.5333 34H33.4333C33.7468 34 34 33.7468 34 33.4333V14.7333C34 14.5825 33.9405 14.4387 33.834 14.3327L28.3333 8.83207ZM1.13333 17.5667V2.83333C1.13333 1.89621 1.89621 1.13333 2.83333 1.13333H25.5C26.4371 1.13333 27.2 1.89621 27.2 2.83333V7.69873L26.6046 7.10324C26.0434 6.54217 25.2977 6.23333 24.5039 6.23333C22.8661 6.23333 21.5333 7.56613 21.5333 9.20394C21.5333 9.9972 21.8422 10.7434 22.4032 11.3046L24.9333 13.8347V17.5667C24.9333 17.7175 24.9928 17.8613 25.0994 17.9673L26.2277 19.0957C26.0008 19.2038 25.7556 19.2667 25.5 19.2667H2.83333C1.89621 19.2667 1.13333 18.5038 1.13333 17.5667ZM19.2667 21.8654V20.4H25.5C26.0611 20.4 26.5998 20.2357 27.0617 19.9302L27.9321 20.8007L28.7334 19.9994L26.0667 17.3321V13.6C26.0667 13.4492 26.0072 13.3053 25.9006 13.1994L23.2046 10.5032C22.8576 10.1563 22.6667 9.69446 22.6667 9.20387C22.6667 8.19067 23.4907 7.36667 24.5039 7.36667C24.9945 7.36667 25.4563 7.55756 25.8032 7.90457L32.8667 14.968V27.2H22.1V24.9333C22.1 24.7825 22.0405 24.6387 21.934 24.5327L19.2667 21.8654ZM22.1 32.8667V28.3333H32.8667V32.8667H22.1Z"
            fill={props.fill || '#262626'}
          />
          <path
            d="M2.99928 6.40002L3.80055 7.20128L5.09992 5.90192L6.39928 7.20128L7.20055 6.40002L6.20782 5.40722L7.50379 5.08379L7.22938 3.98418L5.66658 4.37489V2.83398H4.53325V4.37489L2.97045 3.98418L2.69604 5.08379L3.99201 5.40722L2.99928 6.40002ZM9.79928 6.40002L10.6005 7.20128L11.8999 5.90192L13.1993 7.20128L14.0005 6.40002L13.0078 5.40722L14.3039 5.08379L14.0294 3.98418L12.4666 4.37489V2.83398H11.3332V4.37489L9.77045 3.98418L9.49597 5.08379L10.792 5.40722L9.79928 6.40002ZM20.8294 3.98418L19.2666 4.37489V2.83398H18.1332V4.37489L16.5705 3.98418L16.296 5.08379L17.592 5.40722L16.5993 6.40002L17.4005 7.20128L18.6999 5.90192L19.9993 7.20128L20.8005 6.40002L19.8078 5.40722L21.1038 5.08379L20.8294 3.98418ZM6.39928 12.868L7.20055 12.0667L6.20782 11.0739L7.50379 10.7505L7.22938 9.65084L5.66658 10.0416V8.50065H4.53325V10.0416L2.97045 9.65084L2.69604 10.7505L3.99201 11.0739L2.99928 12.0667L3.80055 12.868L5.09992 11.5686L6.39928 12.868ZM10.6005 12.868L11.8999 11.5686L13.1993 12.868L14.0005 12.0667L13.0078 11.0739L14.3039 10.7505L14.0294 9.65084L12.4666 10.0416V8.50065H11.3332V10.0416L9.77045 9.65084L9.49597 10.7505L10.792 11.0739L9.79928 12.0667L10.6005 12.868ZM4.53325 17.0007H9.06658V18.134H4.53325V17.0007ZM10.1999 17.0007H14.7332V18.134H10.1999V17.0007ZM4.53325 14.734H5.66658V15.8673H4.53325V14.734ZM6.79992 14.734H7.93325V15.8673H6.79992V14.734ZM23.2332 29.4673H24.3666V30.6007H23.2332V29.4673Z"
            fill={props.fill || '#262626'}
          />
        </svg>
      </>
    );
  };
  
  export default OmniRetainIcon;
  