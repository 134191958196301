import React from "react"

const LeftRightArrows = ({ ...props }) => {
  return (
    <>
      <svg
        className={props.className}
        width="14"
        height="14"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={props.onClick}
      >
        <path
          d="M15.5 5C15.5 5.225 15.425 5.375 15.275 5.525L11.525 9.275C11.225 9.575 10.775 9.575 10.475 9.275C10.175 8.975 10.175 8.525 10.475 8.225L13.7 5L10.475 1.775C10.175 1.475 10.175 1.025 10.475 0.724999C10.775 0.424999 11.225 0.424999 11.525 0.724999L15.275 4.475C15.425 4.625 15.5 4.775 15.5 5ZM5.75 8.75C5.75 8.975 5.675 9.125 5.525 9.275C5.225 9.575 4.775 9.575 4.475 9.275L0.725 5.525C0.425 5.225 0.425 4.775 0.725 4.475L4.475 0.724999C4.775 0.424999 5.225 0.424999 5.525 0.724999C5.825 1.025 5.825 1.475 5.525 1.775L2.3 5L5.525 8.225C5.675 8.375 5.75 8.525 5.75 8.75Z"
          fill="black"
        />
      </svg>
    </>
  )
}

export default LeftRightArrows
