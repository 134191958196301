import { Layout, Menu } from "antd"
import React, { useState, useEffect } from "react"

import useWindowSize from "../../NavBar/lib/custom-hooks/useWindowSize"
import "../assets/SideBar.scss"
import "../assets/SideBar.css"
import XstakLogoIcon from "../../icons/xstakLogo"
import XstakLogoTextIcon from "../../icons/xstakLogoText"
// import { SideBarProps } from '../types';

export type SideBarProps = {
  width?: number | string
  isCollapsible?: boolean
  handleSideBarCollapse?: (e: React.MouseEvent<HTMLInputElement>) => void
  header?: React.ReactNode | string
  menues: Array<SingleMenu>
  footer?: React.ReactNode
  showFullScreen?: boolean
  collapsed?: boolean
  onClickOption: () => void
  openKeys?: any
  selectedKeys?: string[]
  handleOpenSubMenu?: any
  app?: string
  location?: any
}

export type MenuInterface = {
  url: string
  icon?: React.ReactNode
  title?: string
  disabled?: boolean
  wholeLink?: React.ReactNode
  permissionCode?: Array<string>
}

export type SingleMenu = {
  subMenues?: Array<MenuInterface>
} & MenuInterface

export const SideBar = ({
  width = 256,
  header = "MENU",
  menues = [],
  isCollapsible = false,
  showFullScreen,
  collapsed,
  onClickOption,
  openKeys,
  selectedKeys,
  handleOpenSubMenu,
  app,
  location,
}: SideBarProps) => {
  const { Sider } = Layout
  const { SubMenu } = Menu
  const size = useWindowSize()
  const isShopdesk: boolean = app?.toLowerCase() === "sd"

  const [slectedKeys, setSlectedKeys] = useState(selectedKeys)

  const [selectedMenu, setSelectedMenu] = useState<string>(
    menues[0]?.subMenues?.[0]?.url ?? menues[0]?.url ?? ""
  )

  useEffect(() => {
    const currentUrl = location?.pathname
    if (!isShopdesk) {
      setSelectedMenu(currentUrl)
    }
  }, [location?.pathname])
  return (
    <Sider
      width={width}
      trigger={null}
      className={`side_bar  ${app} ${
        showFullScreen ? "full-size-sidebar  " : "hide-side-bar"
      }`}
      collapsed={collapsed}
      collapsible={isCollapsible}
    >
      {size[0] > 768 ? (
        <>
          {collapsed ? (
            <div
              className="w-10 h-10 m-[12px] p-1.5 cursor-pointer rounded-md"
              onClick={() => window.location.assign("/app")}
            >
              <XstakLogoIcon />
            </div>
          ) : (
            <div
              className="w_121 h-10 ml-7 m-4 cursor-pointer "
              onClick={() => window.location.assign("/app")}
            >
              <XstakLogoTextIcon />
            </div>
          )}
        </>
      ) : null}
      <Menu
        mode="inline"
        className="menu_items left_menu_scroll"
        openKeys={openKeys}
        selectedKeys={isShopdesk ? slectedKeys : [selectedMenu]}
        onOpenChange={handleOpenSubMenu}
        defaultOpenKeys={isShopdesk ? slectedKeys : [selectedMenu]}
      >
        {menues?.map((menu, pIndex) => {
          return (
            <React.Fragment key={pIndex}>
              {menu?.subMenues ? (
                <SubMenu
                  key={menu.url}
                  icon={menu.icon}
                  title={menu.title}
                  popupClassName={`custom-popup-container ${app?.toLowerCase()}`}
                >
                  {collapsed && (
                    <h2 className="submenu-top-~heading text-base font-bold text-white p-[8px] py-[12px] mb-[0rem]">
                      {menu.title}
                    </h2>
                  )}
                  {menu.subMenues.map((sub, index) => (
                    <React.Fragment key={index}>
                      <Menu.Item
                        key={sub.url}
                        icon={sub.icon}
                        className="dropdownChildMenus"
                        onClick={() => {
                          isShopdesk
                            ? setSlectedKeys([sub?.url])
                            : setSelectedMenu(sub?.url)
                          document.body.style.overflow = "auto"
                          onClickOption()
                        }}
                      >
                        {sub.wholeLink}
                      </Menu.Item>
                    </React.Fragment>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={menu.url}
                  icon={menu.icon}
                  disabled={!!menu.disabled}
                  onClick={() => {
                    isShopdesk
                      ? setSlectedKeys([menu?.url])
                      : setSelectedMenu(menu.url)
                    document.body.style.overflow = "auto"
                    onClickOption()
                  }}
                >
                  {menu.wholeLink}
                </Menu.Item>
              )}
            </React.Fragment>
          )
        })}
      </Menu>
      {!collapsed && size[0] > 768 && (
        <div className="side_bar__footer flex flex-col">
          <p className="term_text text-white">© 2023 XStak Inc.</p>
          <ul className="flex term_text">
            <li>
              <a
                className="term_text text-white"
                href="https://www.xstak.com/privacy-policy"
                target="_blank"
              >
                Terms of Service
              </a>
            </li>
            <li>
              <a
                className="term_text text-white"
                href="https://www.xstak.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      )}
    </Sider>
  )
}
