import React from "react";

function XstakLogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_9382_342)">
        <rect width="40" height="40" fill="#262626" rx="6"></rect>
        <path
          fill="#7E62D1"
          d="M23.384 5.008h-6.777v29.971h6.777V5.008z"
          opacity="0.6"
        ></path>
        <path
          fill="#7E62D1"
          d="M34.99 16.582H5v6.772h29.99v-6.772z"
          opacity="0.8"
        ></path>
        <path
          fill="#7E62D1"
          d="M11.805 6.997l-4.792 4.788L28.22 32.978l4.792-4.789L11.805 6.997z"
        ></path>
        <path
          fill="#7E62D1"
          d="M28.22 6.963L7.013 28.156l4.792 4.789 21.207-21.193-4.792-4.789z"
        ></path>
        <path
          fill="#679CE0"
          d="M23.378 5h-6.773v4.737h6.773V5zM11.81 6.998l-4.784 4.791 3.356 3.346 4.783-4.791-3.355-3.346zM29.659 24.834l-4.784 4.791 3.355 3.346 4.784-4.792-3.356-3.345zM28.216 6.975L24.86 10.32l4.784 4.791 3.355-3.345-4.783-4.791zM10.366 24.81l-3.355 3.346 4.783 4.791 3.356-3.345-4.784-4.791zM23.378 30.262h-6.773v4.737h6.773v-4.737zM9.744 16.586h-4.74v6.768h4.74v-6.768zM35 16.586h-4.74v6.768H35v-6.768z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_9382_342">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default XstakLogoIcon;
