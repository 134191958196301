import React from "react"
import {
  UserOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  CloseOutlined,
  MenuOutlined,
} from "@ant-design/icons"

import { Dropdown, Menu } from "antd"
import "../../../index.css"
import useWindowSize from "../../../lib/custom-hooks/useWindowSize"
import { SideBar } from "../../../../Drawer/components/SideBar"
import XstakLogoTextIcon from "../../../../icons/xstakLogoText"


export const MobileHeader = ({
  collapsed,
  handleSideBarCollapse,
  openKeys,
  selectedKeys,
  handleOpenSubMenu,
  menu,
  extra,
  app,
}) => {
  const size = useWindowSize()
  const components = {
    hideCollapse: size[0] > 768 ? RightCircleFilled : MenuOutlined,
    showCollapse: size[0] > 768 ? LeftCircleFilled : CloseOutlined,
  }
  const IconComponent = components[collapsed ? "hideCollapse" : "showCollapse"]
  const logout = () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.assign("/auth/login")
  }
  const profile = (
    <Menu className="mob-logout-btn">
      <Menu.Item danger>
        <button
          onClick={() => {
            window.sessionStorage.removeItem('module');
            logout()
          }}
          className="header_logout_btn"
        >
          Log out
        </button>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className=" h-[56px] bg-black flex z-50 sticky w-full top-0">
      <div className="w-full flex items-center justify-between mx-2">
        <div className="flex mobile_header_dropdown">
          <SideBar
            width={size[0] < 768 ? "100%" : 250}
            isCollapsible
            header="MENU"
            menues={menu}
            collapsed={collapsed}
            onClickOption={handleSideBarCollapse}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            handleOpenSubMenu={handleOpenSubMenu}
            app={app}
          />

          <IconComponent
            className={`icon-center ${
              collapsed ? "collapsed " : "full-size menu-icon"
            }`}
            onClick={() => {
              if (collapsed) {
                document.body.style.overflow = "hidden"
              } else {
                document.body.style.overflow = "auto"
              }
              handleSideBarCollapse(true)
            }}
          />
        </div>
        <div className="cursor-pointer"  onClick={() => window.location.assign("/app")}>
          <XstakLogoTextIcon />
        </div>
        <div className="text-white icon-center ">
        {extra}
          <Dropdown
            overlay={profile}
            trigger={["click"]}
            className="mobile_footer_dropdown"
          >
            <a onClick={(e) => e.preventDefault()}>
              <>
                <UserOutlined className="overflow-hidden !text-[25px]  max-h-[32px]  !text-white" />
              </>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
